<template>
    <card-table-search :fields="fields"
                       :actions="actions"
                       :items="items"
                       :custom-rendered="['imageExtension', 'proprietaire']"
                       :exportColumns="fields.filter(item => item.key !== 'imageExtension')"
                       class="table-body-lh-40"
                       title="Liste des jeux de rôle">
        <template #title>
            Liste des jdrs
            <b-button pill variant="success" size="sm" class="ml-2" v-b-tooltip.hover="'Ajouter un jdr'"
                      @click="editJdrModal(null)">
                <font-awesome-icon icon="plus"/>
            </b-button>
        </template>
        <template #imageExtension="slotProps">
            <img :src="thumbnailSrc(slotProps.row.item, 'jdrs')" alt="Image du jdr" class="thumbnail-object-image"/>
        </template>
        <template #proprietaire="slotProps">
            <user-name :user="slotProps.row.item.proprietaire" default-cluji/>
        </template>
    </card-table-search>
</template>

<script>
    import {thumbnailSrc} from '@/util/image';
    import {apiPath}      from '@/util/http';
    import {textUsername} from '@/util/text';
    import alert          from '@/util/alert';

    const CardTableSearch   = () => import('@/components/CardTableSearch');
    const AdminEditJdrModal = () => import('@/components/modals/AdminEditJdrModal');
    const DisplayJdrModal   = () => import('@/components/modals/DisplayJdrModal');
    const UserName          = () => import('@/components/UserName');

    export default {
        name: "AdminJdrs",
        components: {CardTableSearch, UserName},
        data() {
            return {
                fields: [
                    {
                        key: 'imageExtension',
                        label: 'Image',
                        sortable: false
                    },
                    {
                        key: 'nom',
                        label: 'Nom',
                        sortable: true
                    },
                    {
                        key: 'caution',
                        label: 'Caution',
                        sortable: true,
                        formatter: value => value ? value + ' €' : ''
                    },
                    {
                        key: 'proprietaire',
                        label: 'Propriétaire',
                        sortable: true,
                        formatter: value => value ? textUsername(value) : '' // Only used for export, it's customRendered
                    },
                    {
                        key: 'actions',
                        label: 'Actions'
                    }
                ],
                actions: [
                    {
                        key: 'action_display',
                        color: () => 'secondary',
                        icon: 'eye',
                        tooltip: 'Afficher',
                        handler: ({item}) => this.displayJdrModal(item)
                    },
                    {
                        key: 'action_edit',
                        color: () => 'info',
                        icon: 'pen',
                        tooltip: 'Éditer',
                        handler: ({item}) => this.editJdrModal(item)
                    },
                    {
                        key: 'action_delete',
                        color: () => 'danger',
                        icon: 'trash',
                        tooltip: "Supprimer",
                        handler: ({item}) => this.deleteJdr(item)
                    }
                ],
                items: []
            };
        },
        methods: {
            thumbnailSrc,
            loadData() {
                alert.loading();
                this.axios.get(apiPath('admin_list_jdrs'))
                    .then(response => this.items = response.data)
                    .catch(() => this.$toaster.error('Impossible de récupérer la liste des jeux de rôle'))
                    .finally(() => alert.stopLoading());
            },
            displayJdrModal(jdr) {
                this.$store.dispatch('modal/create', {
                    component: DisplayJdrModal,
                    props: {jdr: jdr}
                });
            },
            editJdrModal(jdr) {
                this.$store.dispatch('modal/create', {
                    component: AdminEditJdrModal,
                    props: {jdr: jdr, callback: () => this.loadData()}
                });
            },
            deleteJdr(jdr) {
                alert.confirm('Supprimer le jdr ' + jdr.nom + ' ?', () => {
                    this.axios.delete(apiPath('admin_delete_jdr', {jdr: jdr.id}))
                        .then(response => {
                            this.$toaster.success('Jeu de rôle ' + (response.data.softDelete ? 'archivé' : 'supprimé') + ' avec succès');
                            this.loadData();
                        })
                        .catch(() => this.$toaster.error('Impossible de supprimer le jeu de rôle'));
                });
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>

<style scoped>
    .thumbnail-object-image {
        max-width: 40px;
        max-height: 40px;
    }
</style>
